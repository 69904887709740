import * as React from "react";
import { Seo, Header } from "components";
import styled from "styled-components";
import {
  colors,
  breakpoints,
  FullWidthContent,
  Section,
  Container,
} from "styles";
import { graphql } from "gatsby";

const PrivacyPolicyPage = ({ data }) => {
  const { title, policies } = data.markdownRemark.frontmatter;
  return (
    <>
      <Seo title={title} />
      <Header data={data} title={title} />
      <Section>
        <Container style={{ gridGap: ".25rem" }}>
          <FullWidthContent style={{ alignItems: "flex-start" }}>
            {policies.map((policy) => (
              <Policy $colors={colors}>
                <h3 dangerouslySetInnerHTML={{ __html: policy.title }} />
                <p dangerouslySetInnerHTML={{ __html: policy.text }} />
              </Policy>
            ))}
          </FullWidthContent>
        </Container>
      </Section>
    </>
  );
};

const Policy = styled.div`
  margin-bottom: 3rem;
  h3 {
    margin-bottom: 0.5rem;
    color: ${colors.red};
  }
  p {
    ul {
      list-style-type: disc;
      padding-left: 5rem;
      li {
        padding-bottom: 0.5rem;
      }
    }
    ol {
      padding-left: 5rem;
      padding-top: 1rem;
      li {
        padding-bottom: 0.5rem;
        small {
          font-size: 0.5rem;
        }
      }
    }
    span {
      font-size: 0.875rem;
      small {
        font-size: 0.5rem;
      }
    }
  }
`;

export default PrivacyPolicyPage;

export const query = graphql`
  query PrivaryPolicyQuery {
    markdownRemark(frontmatter: { title: { eq: "Privacy Policy" } }) {
      frontmatter {
        title
        policies {
          title
          text
        }
      }
    }
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;
